.products{
    margin-top: 90px;
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: #232a31;
}

.products .header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.products .header h1{
    color: #fff;
    width: 48%;
    font-weight: 400;
}

.products .header h1 span{
    color: #fb7418;
    text-decoration: underline;
}

.products .header .buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.products .header .buttons button{
    background-color: #fff;
    width: 50px;
    height: 50px;
    border: none;
    outline: none;
    color: #fb7418;
    font-size: 28px;
}


.products .products-content .swiper-slide{
    width: fit-content;
}

.products .products-content .product{
    width: 300px;
    height: 350px;
    margin-right: 37px;
    position: relative;
    margin-bottom: 60px;
    margin-top: 30px;
}


.products .products-content .product img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.products .products-content .product .text a{
    align-items: center;
    position: absolute;
    width: 90%;
    bottom: 10px;
    transform: translateX(-50%);
    background-color: #FfF;
    left: 50%;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 5px 10px;
    color: #000;
}

.products .products-content .product .text span{
    position: absolute;
    top: 10px;
    right: 10px;
    color: #000;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}


.products .products-content .product .text a i{
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #fb7418;
    color: #fff;
    display: flex;
}
