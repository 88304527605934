
.social-section .social-content .map{
    position: relative;
}

.social-section .social-content .map .contact-info{
    border-bottom: 4px solid #fb7418;
    width: 228px;
    min-height: 250px;
    position: absolute;
    top: 20px;
    right: 100px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 17px;
}


.social-section .social-content .map .contact-info.sec{
    border-bottom: 4px solid #fb7418;
    width: 228px;
    min-height: 250px;
    position: absolute;
    top: 20px;
    left: 100px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 17px;
}

.social-section .social-content .map .contact-info .info{
    margin-bottom: 17px;
}

.social-section .social-content .map .contact-info .info h2{
    font-size: 14px;
}

.social-section .social-content .map .contact-info .info h2 i{
    color: #fb7418;
    font-size: 19px;
}

.social-section .social-content .map .contact-info .info p{
    font-size: 13px;
}


.social-section .social-content .social ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}

.social-section .social-content .social ul li{
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #00000029;
    width: 20%;
    color: #000;
}


.social-section .social-content .social ul li a{
    color: #000;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}


@media(max-width:950px){
    .social-section .social-content .map .contact-info.sec {
        border-bottom: 4px solid #fb7418;
        width: 228px;
        min-height: 250px;
        position: absolute;
        bottom: 20px;
        left: 17px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 17px;
        top: 70%;
    }

    iframe{margin-bottom: 30px;}

    .social-section .social-content .map .contact-info {
        border-bottom: 4px solid #fb7418;
        width: 228px;
        min-height: 250px;
        position: absolute;
        top: 138px;
        left: 10px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 17px;
    }

    .social-section .social-content .social ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        flex-wrap: wrap;
    }

    .social-section .social-content .social ul li {
        height: 90px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px solid #00000029;
        width: 30%;
        color: #000;
        margin-bottom: 20px;
    }
}