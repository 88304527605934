.upper-nav{
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 4;
}

.upper-nav .upper-nav-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.upper-nav .upper-nav-content .left{
    width: 33%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 28px;
}

.upper-nav .upper-nav-content .left a{
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    padding-right: 12px;
    border-right: 2px solid #fd6701;
    font-size: 14px;
}

.upper-nav .upper-nav-content .left a i{
    
    font-size: 18px;
    color: #fa6406;

}

.upper-nav .upper-nav-content .middle{
    width: 33%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
}

.upper-nav .upper-nav-content .middle img{
    background-color: #fff;
    padding: 6px;
    width: 183px;
    height: 73px;
    object-fit: contain;
}


.upper-nav .upper-nav-content .right{
    width: 33%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    color: #fff;
}


.upper-nav .upper-nav-content .right a{
    color: #fff;
    font-size: 20px;
}


@media(max-width:950px){
    .upper-nav .upper-nav-content .left {
        display: none;
    }

.upper-nav .upper-nav-content .middle {
    width: 49%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
}

.upper-nav .upper-nav-content .middle .logo{
    width: 100%;
    
}

.upper-nav .upper-nav-content .right {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
    color: #fff;
}

}