
.about .about-img{
    position: relative;
    margin-top: -90px;
}

.about .about-img h3{
    position: absolute;
    right: -100px;
    transform: rotate(-89deg);
    background-color: #fff;
    top: 186px;
    padding: 5px 20px 5px 20px;
    font-size: 16px;
}

.about .about-img h3 span{
    font-size: 18px;
    color: #fb6e0e;
}


.about .about-img img{
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.about .about-img .number{
    background-color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 150px;
    height: 150px;
    padding: 10px;
    background-color: #fb6e0e;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about .about-img .number h1{
    font-size: 54px;
}

.about .about-img .number h2{
    font-size: 16px;
    text-align: center;
}

.about .about-content{
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    justify-content: space-between;
    min-height: 273px;
    margin-bottom: 30px;
}

.about .about-content i{
    font-size: 49px;
    color: #fb6e0e;
}

.about .about-content h1{
    margin-top: 11px;
    font-size: 20px;
    font-weight: 500;
    color: #000;
}

.about .about-content p{
    font-size: 14px;
    color: #616161;
}

.about .about-content a{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 13px;
    color: #000;
}

.about .about-content a i{
    font-size: 17px;
    color: #000;
}


.about .about-contact{
    width: fit-content;
    margin-top: 40px;
}

.about .about-contact h1{
    font-size: 15px;
    width: 100%;
    font-weight: 400;
    gap: 5px;
    height: 40px;
    border: 1px solid #00000054;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 25px 5px 25px;
}

.about .about-contact h1 span{
    color: #fb6e0e;
}

.about .about-contact h1 a{
    color: #000;
}

@media(max-width:950px){
    .about .about-img {
        position: relative;
        margin-top: 23px;
    }

    .about .about-content {
        margin-top: 90px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        justify-content: space-between;
        min-height: 220px;
        margin-bottom: -21px;
    }
}