.contact{
    margin-bottom: 90px;
}


.contact .contact-images{
    position: relative;
}

.contact .contact-images .back-lay{
    position: absolute;
    top: 50px;
    height: 441px;
    background-color: #fb7418;
    width: 94%;
    z-index: -1;
}

.contact .contact-images img{
    width: 90%;
    height: 550px;
    object-fit: cover;
}

.contact .contact-images .contact-numbers{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.contact .contact-images .contact-numbers .number{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    margin-top: 18px;
}

.contact .contact-images .contact-numbers .number h2{
    font-size: 27px;
    color: #fb7418;
}


.contact .contact-images .contact-numbers .number h3{
    font-size: 16px;
    font-weight: 400;
    color: #575757;
}

.contact .contact-form{
    padding: 60px;
}

.contact .contact-form h1{
    font-size: 35px;
    font-weight: 400;
    color: #000;
}

.contact .contact-form h1 span{
    color: #fb7418;
}


.contact .contact-form form{
    
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 41px;

}

.contact .contact-form form .inputs{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
}

.contact .contact-form form .inputs .input{
    width: 100%;
}


.contact .contact-form form .inputs input{
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid #00000029;
    height: 46px;
    font-size: 15px;
}


.contact .contact-form form .inputs textarea{
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid #00000029;
    height: 188px;
    font-size: 15px;
}

.contact .contact-form form button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fb6e0e;
    width: 210px;
    height: 44px;
    margin-right: 17px;
    color: #fff;
    gap: 10px;
    transition: 0.3s;
    outline: none;
    border: none;
}

.swiper-pagination-bullet{
    background-color: #fb6e0e !important;
}

@media(max-width:950px){
    .contact .contact-form {
        padding-top: 52px;
    }

    .contact .contact-images .contact-numbers .number {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 18px;
    }
}