.benefits{
    margin-top: 120px;
    margin-bottom: 80px;
}


.benefits .benefits-header h1{
    font-size: 42px;
    font-weight: 400;
    color: #000;
}

.benefits .benefits-header h1 span{
    color: #fb6e0e;
    text-decoration: underline;
}

.benefits .partners{
    margin-bottom: 62px;
}

.benefits .partners .swiper-slide{
    width: fit-content;
}

.benefits .partners img{
    width: 129px;
    height: 129px;
    object-fit: contain;
}


.benefits .benefits-text{
    display: flex;
    flex-direction: row;
    gap: 60px;
    justify-content: center;
    align-items: center;
}

.benefits .benefits-text .text{
    margin-top: 30px;
    margin-bottom: 60px;
    width: 80%;
}

.benefits .benefits-text .text h2{
    font-size: 20px;
    font-weight: 400;
    color: #000;
}

.benefits .benefits-text .text p{
    font-size: 16px;
    color: #707070;
    margin-bottom: 53px;
    margin-top: 12px;
}


.benefits .benefits-text .text ul li{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 7px;
    font-size: 16px;
    font-weight: 500;
    color: #878787;
}

.benefits .benefits-text .text ul li i{
    font-size: 19px;
    color: #fb6e0e;
}


.benefits .benefits-text .exp{
    height: 300px;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #fb6e0e;
}


.benefits .benefits-text .exp h3{
    font-size: 48px;
    color: #fb7418;
}

.benefits .benefits-text .exp h4{
    font-size: 15px;
}

.benefits .benefits-content{
    height: 432px;
    overflow-y: scroll;
}



.benefits .benefits-content .benefit h5{
    font-size: 17px;
    font-weight: 500;
    color: #000;
}

.benefits .benefits-content .benefit p{
    margin-bottom: 32px;
    font-size: 16px;
    width: 90%;
    color: #555;
}


.benefits .benefits-content::-webkit-scrollbar {
    width: 5px;
    border-radius: 15px;
}

/* Track */
.benefits .benefits-content::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 15px;
}

/* Handle */
.benefits .benefits-content::-webkit-scrollbar-thumb {
    background: #fb6e0e; 
    border-radius: 15px;
}

/* Handle on hover */
.benefits .benefits-content::-webkit-scrollbar-thumb:hover {
    background: #fb6e0e; 
    border-radius: 15px;
}

@media(max-width:950px){
    .benefits .benefits-text {
        display: flex;
        flex-direction: column;
        gap: 60px;
        justify-content: center;
        align-items: center;
    }

    .benefits .benefits-text .exp {
        height: 300px;
        width: 61%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1px solid #fb6e0e;
        margin-bottom: 35px;
    }
    
}