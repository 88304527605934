.landing{
    background-image: url('../images/landingimg.webp');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    transition: 0.3s;
    min-height: 650px;
    position: relative;
    padding-top: 172px;
}



.landing>.overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #000000a3;
}

.landing .landing-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.landing .landing-content .text{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 50px;
    position: relative;
}

.landing .landing-content .text .left{
    width: 49%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.landing .landing-content .text .left h1{
    font-size: 136px;
    color: #fb6e0e;
    font-weight: 500;
}


.landing .landing-content .text .left h2{
    font-size: 20px;
    color: #FFF;
    font-weight: 400;
}

.landing .landing-content .text .line{
    width: 2px;
    height: 620px;
    background-color: #fb6e0e;
}


.landing .landing-content .text .right{
    width: 49%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 38px;
}

.landing .landing-content .text .right h3{
    width: 180px;
    height: 35px;
    background-color: #fb6e0e;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 400;
}


.landing .landing-content .text .right h1{
    font-size: 40px;
    color: #fff;
    font-weight: 500;
}


.landing .landing-content .text .right p{
    font-size: 16px;
    color: #fff;
}


.landing .landing-content .text .right .contact-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}


.landing .landing-content .text .right .contact-info a:nth-child(1){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fb6e0e;
    width: 210px;
    height: 44px;
    margin-right: 17px;
    color: #fff;
    gap: 10px;
    transition: 0.3s;
}

.landing .landing-content .text .right .contact-info a:nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    width: 50px;
    height: 50px;
    color: #fff;
    border-radius: 50%;
    font-size: 20px;
}


@media(max-width:950px){
    .landing .landing-content .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 50px;
        position: relative;
    }

    .landing .landing-content .text .right {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 38px;
        margin-top: 23px;
    }

    .landing .landing-content .text .line {
        width: 100%;
        height: 1px;
        background-color: #fb6e0e;
    }

    .landing .landing-content .text .left {
        width: 49%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .landing .landing-content .text .left h2 {
        font-size: 20px;
        color: #FFF;
        font-weight: 400;
        width: 100%;
        text-align: center;
    }

    .landing .landing-content .text .left h1 {
        font-size: 67px;
        color: #fb6e0e;
        font-weight: 500;
    }
}