.services{
    margin-bottom: 80px;
}



.services .service{
    position: relative;
}


.services .service img{
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.services .service .text{
    position: absolute;
    bottom: 0;
    background-color: #fff;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    padding: 18px;
    min-height: 230px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.services .service .text .icon{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
}


.services .service .text .icon span{
    font-size: 30px;
    font-weight: 600;
    color: #fb6e0e;
    margin-left: -16px;
}


.services .service .text .icon i{
    font-size: 67px;
    color: #0000001f;
}

.services .service .text h1{
    font-size: 22px;
    font-weight: 400;
}


.services .service .text p{
    font-size: 15px;
    color: #7a7a7a;
}


.services .service .text a{
    font-size: 14px;
    color: #000;
}


@media(max-width:950px){
    .services .service {
        position: relative;
        margin-bottom: 61px;
    }
}