
.page-header{
    min-height: 467px;
    position: relative;
    margin-bottom: 114px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.page-header .overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #000000eb;
}

.page-header img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 1;
}


.page-header h1{
    position: relative;
    z-index: 3;
    color: #fff;
    font-size: 64px;
    margin-top: 57px;
}

.page-header ul{
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 0;
    margin-top: 44px !important;
    color: #fff;
}



.page-header ul li a{
    color: #fff;
    font-size: 20px;
}

.about-page .about-page-img{
    margin-bottom: 40px;
}


.about-page .about-page-img img{
    width: 100%;
    object-fit: cover;
}

.about-page .about-page-content{
    margin-bottom: 131px;
}

.about-page .about-page-content h3{
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 400;
    color: #f96901;
}

.about-page .about-page-content h1{
    color: #000;
    margin-bottom: 38px;
}

.about-page .about-page-content p{
    font-size: 16px;
    color: #6e6b6b;
    margin-top: 36px;
    font-weight: 400;
}

.about-page .about-page-content div{
    display: flex;
    flex-direction: row;
    gap: 31px;
    margin-top: 40px;
    margin-bottom: 41px;
    flex-wrap: wrap;
}


.about-page .about-page-content div img{
    width: 150px;
    border-radius: 9px;
}

.about-page .about-page-content div h2{
    font-size: 19px;
    color: #000;
    margin-top: 36px;
    font-weight: 400;
    font-weight: 400;
}


.about-page .about-page-content a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fb6e0e;
    width: 210px;
    height: 44px;
    margin-right: 17px;
    color: #fff;
    gap: 10px;
    transition: 0.3s;
    
}