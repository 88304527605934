nav{
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 4;
    top: 80px;
}

nav .nav-content{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    min-height: 80px;

}


nav .nav-content::before{
    content: "";
    position: absolute;
    border-width: 40px;
    border-style: solid;
    color: #fa6902;
    border-color: transparent transparent #fb6e0e #fb6e0e;
}



nav .nav-content .nav-elements ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
    color: #000;
    z-index: 1;
    position: relative;
}

nav .nav-content .nav-elements ul li a{
    color: #000;
}

nav .nav-content .contact-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
}

nav .nav-content .contact-info .phone{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}


nav .nav-content .contact-info .phone>i{
    font-size: 19px;
    background-color: #eee;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}




nav .nav-content .contact-info .phone .text h2{
    font-size: 14px;
    margin: 0;
}


nav .nav-content .contact-info .phone .text a{
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 400;
    color: #f96901;
}

nav .nav-content .contact-info>a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fb6e0e;
    width: 210px;
    height: 44px;
    margin-right: 17px;
    color: #fff;
    gap: 10px;
    transition: 0.3s;
}





#open-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
    color: #ffff;
}

#close-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
}


@media(max-width:950px){

    
    #open-nav{
        display: block;
        z-index: 1;
    }

    #close-nav{
        display: block;
    }

    nav .nav-content .nav-elements {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 60px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        height: 100%;
        width: 100%;
        justify-content: flex-start;
        z-index: 15015;
        padding-top: 80px;
        overflow-y: scroll;
    }

    nav .nav-content .nav-elements ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 0;
        justify-content: center;
        align-items: center;
    }

    
    nav .nav-content .nav-elements{
        display: none;
    }

    nav .nav-content .nav-elements ul li a {
        color: #000;
        font-size: 17px;
        font-weight: 500;
    }

    nav .nav-content .nav-elements ul li .inner-eles {
        align-items: flex-start;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 4px rgba(0,0,0,.42);
        display: flex;
        flex-direction: column;
        gap: 15px;
        left: 0;
        min-height: 150px;
        opacity: 0;
        padding: 7px;
        pointer-events: none;
        position: absolute;
        top: -196px;
        transition: .3s;
        width: 216px;
        z-index: 2000;
    }

    nav .nav-content .nav-elements ul .inner-eles.active{
        opacity: 1;
        pointer-events: inherit;
        position: relative;
        top: 0;
        left: 0;
    }

    nav .nav-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 17px;
        justify-content: space-between;
    }

    nav .nav-content .contact-info .phone {
        display: none;
    }

    nav .nav-content .contact-info>a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #fb6e0e;
        width: 163px;
        height: 39px;
        margin-right: 17px;
        color: #fff;
        gap: 10px;
        transition: 0.3s;
        font-size: 14px;
    }

}


