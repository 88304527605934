footer{
    background-image: url('../images/landingimg.webp');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    transition: 0.3s;
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
}

footer .overlay{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #000000db;
}

footer .footer-content{
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


footer .footer-content .logo img{
    background-color: #fff;
    padding: 16px;
    width: 100%;
    object-fit: contain;
    height: 88px;
}

footer .footer-content p{
    font-size: 16px;
    color: #a0a0a0;
    margin-top: 36px;
    font-weight: 400;
}

footer .footer-content>a{
    font-size: 15px;
    color: #fff;
    display: flex;
    flex-direction: revert;
    flex-direction: row;
    gap: 7px;
    align-items: center;
}


footer .footer-content h1{
    font-size: 21px;
    font-weight: 500;
    color: #fff;
}


footer .footer-content ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 22px;
    margin-top: 32px !important;
    color: #fff;
}


footer .footer-content ul li a{
    color: #bbb;
}



footer .footer-content .images{
    width: 100%;
    margin-top: 37px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
}




footer .copy-right{
    position: relative;
    z-index: 1;
    margin-top: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


footer .copy-right h2{
    font-size: 17px;
    color: #fff;
    font-weight: 300;
}


footer .copy-right .social{
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}


footer .copy-right .social li a{
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: revert;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #1a1a1a;
    color: #fff;
    font-size: 19px;
}


@media(max-width:950px){
    footer .footer-content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 40px;
    }

    footer .footer-content .images img{
        width: 32%;
        
    }

    footer .copy-right {
        position: relative;
        z-index: 1;
        margin-top: 90px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        gap: 22px;
    }
}