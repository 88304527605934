.choose-us{
    margin-top: 90px;
    margin-bottom: 90px;
}

.choose-us .choose-us-content{
    margin-top: 65px;
    padding: 22px;
}

.choose-us .choose-us-content h2{
    font-size: 92px;
    color: #fb6e0e;
}

.choose-us .choose-us-content h1{
    font-size: 41px;
    font-weight: 500;
    color: #000;
}

.choose-us .choose-us-content ul{
    padding: 0;
    margin-top: 63px !important;
    display: flex;
    flex-direction: column;
    gap: 18px;
}


.choose-us .choose-us-content ul li h3{
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin-bottom: 28px;
}

.choose-us .choose-us-content ul li h3 i{
    color: #fb6e0e;
    font-size: 21px;
}

.choose-us .choose-us-content ul li p{
    font-size: 16px;
    color: #595959;
}


.choose-us .choose-us-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}